import { useEffect } from 'react';
import { useRouter } from 'next/router';

const Redirect404Error = () => {
    const router = useRouter();
    useEffect(() => {
        router.replace('/');
    }, [router]);
    return null;
};

export default Redirect404Error;
